import { Dispatch } from 'redux';

import {
  getDischargeDebtsService,
  getEasyDischargeDebtsService,
  getEasyDuplicateInvoicesService,
  getOutsidesInvoicePaymentVoucherService,
  getOutsidesInvoicesInstallmentsService,
  getOutsidesRegistrationUpdateService,
  postDischargeDebtsService,
  postOutsidesInvoicePaymentService,
  postOutsidesRegistrationUpdateService,
  getOutsidesSocialTariffEnrollmentsService,
  getOutsidesSocialTariffValidEnrollmentService,
  postOutsidesSocialTariffService,
  getOutsidesDowloadInvoiceService,
  getCredentialsByTokenService,
  getOutsidesDowloadDebtsService,
} from '~/services/outsides';
import {
  RepositoriesTypesOutsides,
  IResponseEasyInvoices,
  IRequestInvoicePayment,
  IResponseInvoiceVoucher,
  IResponseInvoicePayment,
  IRequestQueryInvoices,
  IResponseInvoiceInstallments,
  IResponseDebts,
  IRequesDebtsPayment,
  IRegistrationUpdate,
  IRequestSocialTariffValidEnrollment,
  IResponseSocialTariffValidEnrollment,
  IResponseSocialTariffEnrollments,
  IRequestSocialTariff,
  IRequestDischargeDebtsRequest,
  IRequestEasyInvoices,
} from './types';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import {
  apiHeaderResetRecaptchaToken,
  apiHeaderSetRecaptchaToken,
} from '~/utils/apiRecaptchaToken';
import { apiHeaderSetOutsideToken } from '~/utils/apiOutsideToken';

const getEasyDuplicateInvoices =
  ({
    cnp,
    recaptchaTokenV2,
    recaptchaTokenV3,
    enrollmentId,
    params,
    routeData,
    paramsDischargeDebts,
  }: IRequestEasyInvoices) =>
  async (dispatch: Dispatch) => {
    console.log(paramsDischargeDebts);
    try {
      dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_EASY_INVOICES_GET_METHOD_REQUEST,
      });

      apiHeaderSetRecaptchaToken({
        recaptchaTokenV2,
        recaptchaTokenV3,
      });

      if (routeData) {
        const response = (await getEasyDuplicateInvoicesService(
          cnp,
          routeData?.enrollment.split('-', 1)[0] || enrollmentId,
          params,
        )) as IResponseEasyInvoices;

        // const responseDischargeDebts = (await getDischargeDebtsService(
        //   cnp,
        //   paramsDischargeDebts,
        // )) as IResponseDebts;

        apiHeaderSetOutsideToken({ token: response?.token });

        Navigation.navigate(RouteName.EASYDUPLICATESINGLEPAYMENT, {
          ...routeData,
          invoices: response?.invoices?.invoices,
          // invoicesDischargeDebts: responseDischargeDebts.dados,
          invoicesDischargeDebts: [],
        });

        apiHeaderResetRecaptchaToken();

        return dispatch({
          type: RepositoriesTypesOutsides.OUTSIDES_EASY_INVOICES_GET_METHOD_SUCCESS,
        });
      }

      const response = (await getEasyDuplicateInvoicesService(
        cnp,
        enrollmentId,
        params,
      )) as IResponseEasyInvoices;

      apiHeaderSetOutsideToken({ token: response?.token });

      const responseDischargeDebts = (await getEasyDischargeDebtsService(
        cnp,
      )) as IResponseDebts;

      dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_DISCHARGE_DEBTS_GET_METHOD_SUCCESS,
        payload: {
          data: responseDischargeDebts,
        },
      });

      apiHeaderResetRecaptchaToken();

      return dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_EASY_INVOICES_GET_METHOD_SUCCESS,
        payload: {
          data: response?.invoices,
        },
      });
    } catch (error: any) {
      apiHeaderResetRecaptchaToken();

      return dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_EASY_INVOICES_GET_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
            message: error?.response?.data?.type,
          },
        },
      });
    }
  };

const getEasyDuplicateInvoicesByToken =
  (token: string, params?: IRequestQueryInvoices) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_EASY_INVOICES_GET_METHOD_REQUEST,
      });

      const response = (await getCredentialsByTokenService(token)) as {
        cnp: string;
        enrollment_id: string;
      };

      const responseInvoices = (await getEasyDuplicateInvoicesService(
        response.cnp,
        response.enrollment_id,
        params,
      )) as IResponseEasyInvoices;

      const responseDischargeDebts = (await getEasyDischargeDebtsService(
        response.cnp,
      )) as IResponseDebts;

      dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_DISCHARGE_DEBTS_GET_METHOD_SUCCESS,
        payload: {
          data: responseDischargeDebts,
        },
      });

      return dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_EASY_INVOICES_GET_METHOD_SUCCESS,
        payload: {
          data: responseInvoices,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_EASY_INVOICES_GET_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const getErrorCloseEasyDuplicateInvoices = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesOutsides.OUTSIDES_EASY_INVOICES_GET_METHOD_FAILURE_CLOSE,
  });
};

const clearEasyDuplicateInvoices = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesOutsides.OUTSIDES_EASY_INVOICES_CLEAR,
  });
};

const activateModalDuplicateInvoices = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesOutsides.OUTSIDES_EASY_ACTIVATE_MODAL,
  });
};

const clearModalDuplicateInvoices = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesOutsides.OUTSIDES_EASY_CLEAR_MODAL,
  });
};

const getOutsidesInvoicesInstallmentsRequest =
  (cnp: string, amount: number, routeData: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_POST_INVOICE_INSTALLMENTS_METHOD_REQUEST,
      });

      const response = (await getOutsidesInvoicesInstallmentsService(
        cnp,
        amount,
      )) as IResponseInvoiceInstallments[];

      dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_POST_INVOICE_INSTALLMENTS_METHOD_SUCCESS,
        payload: {
          data: response,
        },
      });

      return Navigation.navigate(RouteName.EASYDUPLICATESINGLEPAYMENTDATAPAYMENT, {
        ...routeData,
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_POST_INVOICE_INSTALLMENTS_METHOD_FAILURE,
        payload: {
          status: error?.response?.data.status,
          state: true,
        },
      });
    }
  };

const getErrorCloseOutsidesInvoicesInstallments =
  () => async (dispatch: Dispatch) => {
    dispatch({
      type: RepositoriesTypesOutsides.OUTSIDES_POST_INVOICE_INSTALLMENTS_METHOD_FAILURE_CLOSE,
    });
  };

const postOutsidesInvoicePaymentRequest =
  (
    data: IRequestInvoicePayment,
    { cnp, registration }: { cnp: string; registration: string },
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_POST_INVOICE_PAYMENT_METHOD_REQUEST,
      });

      const response = (await postOutsidesInvoicePaymentService(
        {
          ...data,
        },
        registration,
        cnp,
      )) as IResponseInvoicePayment;

      dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_POST_INVOICE_PAYMENT_METHOD_SUCCESS,
      });

      dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_POST_INVOICE_VOUCHER_METHOD_REQUEST,
      });

      const responseVoucher = (await getOutsidesInvoicePaymentVoucherService(
        registration,
        response?.invoice_id || response?.invoice_group_id || 0,
        cnp,
        !!response?.invoice_group_id,
      )) as IResponseInvoiceVoucher;

      dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_POST_INVOICE_VOUCHER_METHOD_SUCCESS,
        payload: {
          data: { ...responseVoucher },
        },
      });

      return Navigation.navigate(
        RouteName.EASYDUPLICATESINGLEPAYMENTDATAPAYMENTRECEIPT,
      );
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_POST_INVOICE_PAYMENT_METHOD_FAILURE,
        payload: {
          status: error?.response?.data.status,
          state: true,
        },
      });
    }
  };

const postErrorCloseOutsidesInvoicePayment = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesOutsides.OUTSIDES_POST_INVOICE_PAYMENT_METHOD_FAILURE_CLOSE,
  });
};

const activateModalSalesforceSatisfaction = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesOutsides.OUTSIDES_SALESFORCE_SATISFACTION_ACTIVATE_MODAL,
  });
};

const clearModalSalesforceSatisfaction = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesOutsides.OUTSIDES_SALESFORCE_SATISFACTION_CLEAR_MODAL,
  });
};

const getDischargeDebtsRequest =
  (
    {
      cnp,
      recaptchaTokenV2,
      recaptchaTokenV3,
      params,
    }: IRequestDischargeDebtsRequest,
    routeData?: any,
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_DISCHARGE_DEBTS_GET_METHOD_REQUEST,
      });

      apiHeaderSetRecaptchaToken({
        recaptchaTokenV2,
        recaptchaTokenV3,
      });

      if (routeData) {
        const response = (await getDischargeDebtsService(
          cnp,
          params,
        )) as IResponseDebts;

        apiHeaderSetOutsideToken({ token: response?.token });

        Navigation.navigate(RouteName.DISCHARGEDEBTSSINGLEPAYMENT, {
          ...routeData,
          invoices: response?.invoices,
        });

        apiHeaderResetRecaptchaToken();

        return dispatch({
          type: RepositoriesTypesOutsides.OUTSIDES_DISCHARGE_DEBTS_GET_METHOD_SUCCESS,
        });
      }

      const response = (await getDischargeDebtsService(
        cnp,
        params,
      )) as IResponseDebts;

      apiHeaderSetOutsideToken({ token: response?.token });

      apiHeaderResetRecaptchaToken();

      // Navigation.navigate(RouteName.DEBTNEGOTIATION);

      // ReactGA.event({
      //   category: 'Negociação de dívidas',
      //   action: '[Negociação de dívidas][Acesso] Acesso liberado',
      // });

      return dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_DISCHARGE_DEBTS_GET_METHOD_SUCCESS,
        payload: {
          data: response?.invoices,
        },
      });
    } catch (error: any) {
      apiHeaderResetRecaptchaToken();

      return dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_DISCHARGE_DEBTS_GET_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
            message: error?.response?.data?.type,
          },
        },
      });
    }
  };

const getErrorCloseDischargeDebts = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesOutsides.OUTSIDES_DISCHARGE_DEBTS_GET_METHOD_FAILURE_CLOSE,
  });
};

const clearDischargeDebts = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesOutsides.OUTSIDES_DISCHARGE_DEBTS_CLEAR,
  });
};

const getDischargeDebtsInstallmentsRequest =
  (cnp: string, amount: number, routeData: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_POST_INVOICE_INSTALLMENTS_METHOD_REQUEST,
      });

      const response = (await getOutsidesInvoicesInstallmentsService(
        cnp,
        amount,
      )) as IResponseInvoiceInstallments[];

      dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_POST_INVOICE_INSTALLMENTS_METHOD_SUCCESS,
        payload: {
          data: response,
        },
      });

      return Navigation.navigate(RouteName.DISCHARGEDEBTSSINGLEPAYMENTDATAPAYMENT, {
        ...routeData,
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_POST_INVOICE_INSTALLMENTS_METHOD_FAILURE,
        payload: {
          status: error?.response?.data.status,
          state: true,
        },
      });
    }
  };

const getErrorCloseDischargeDebtsInstallments = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesOutsides.OUTSIDES_POST_INVOICE_INSTALLMENTS_METHOD_FAILURE_CLOSE,
  });
};

const postDischargeDebtsPaymentRequest =
  (data: IRequesDebtsPayment, cnp: string) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_POST_INVOICE_PAYMENT_METHOD_REQUEST,
      });

      const response = (await postDischargeDebtsService(
        {
          ...data,
        },
        cnp,
      )) as IResponseInvoicePayment;

      dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_POST_INVOICE_PAYMENT_METHOD_SUCCESS,
      });

      dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_POST_INVOICE_VOUCHER_METHOD_REQUEST,
      });

      const responseVoucher = (await getOutsidesInvoicePaymentVoucherService(
        data.enrollment_id,
        response?.invoice_id || 0,
        cnp,
        false,
      )) as IResponseInvoiceVoucher;

      dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_POST_INVOICE_VOUCHER_METHOD_SUCCESS,
        payload: {
          data: { ...responseVoucher },
        },
      });

      return Navigation.navigate(
        RouteName.DISCHARGEDEBTSSINGLEPAYMENTDATAPAYMENTRECEIPT,
      );
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_POST_INVOICE_PAYMENT_METHOD_FAILURE,
        payload: {
          status: error?.response?.data.status,
          state: true,
        },
      });
    }
  };

const postErrorCloseDischargeDebtsPayment = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesOutsides.OUTSIDES_POST_INVOICE_PAYMENT_METHOD_FAILURE_CLOSE,
  });
};

const getOutsidesRegistrationUpdateRequest =
  (cnp: string) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_GET_DATA_UPDATE_METHOD_REQUEST,
      });

      const response = (await getOutsidesRegistrationUpdateService(
        cnp,
      )) as IRegistrationUpdate;

      const isUpdatable = !response.email || !response?.phone;

      return dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_GET_DATA_UPDATE_METHOD_SUCCESS,
        payload: {
          data: {
            ...response,
            cnp,
            isUpdatable,
            cnp_alert: response?.cnp,
          },
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_GET_DATA_UPDATE_METHOD_FAILURE,
        payload: {
          status: error?.response?.data.status,
          state: true,
        },
      });
    }
  };

const postOutsidesRegistrationUpdateRequest =
  (cnp: string, data: IRegistrationUpdate) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_POST_DATA_UPDATE_METHOD_REQUEST,
      });

      const response = (await postOutsidesRegistrationUpdateService(
        cnp,
        data,
      )) as any;

      return dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_POST_DATA_UPDATE_METHOD_SUCCESS,
        payload: {
          data: response,
        },
      });
    } catch (error: any) {
      dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_DATA_UPDATE_CLEAR_MODAL,
      });

      return dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_POST_DATA_UPDATE_METHOD_FAILURE,
        payload: {
          status: error?.response?.data.status,
          state: true,
        },
      });
    }
  };

const postErrorCloseOutsidesRegistrationUpdate =
  () => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesOutsides.OUTSIDES_POST_DATA_UPDATE_METHOD_FAILURE_CLOSE,
    });
  };

const activateModalDataUpdate =
  (typeCopy?: 'pixCode' | 'barCode' | 'download' | '') =>
  async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesOutsides.OUTSIDES_DATA_UPDATE_ACTIVATE_MODAL,
      payload: {
        fromTypeRegistrationUpdate: typeCopy || '',
      },
    });
  };

const clearModalDataUpdate = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesOutsides.OUTSIDES_POST_DATA_UPDATE_METHOD_FAILURE_CLOSE,
  });

  return dispatch({
    type: RepositoriesTypesOutsides.OUTSIDES_DATA_UPDATE_CLEAR_MODAL,
  });
};

const getInvoiceDownload =
  (
    invoiceId: string | number,
    type: 'invoice' | 'charge',
    cnp: string,
    enrollmentId?: string,
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_DOWNLOAD_METHOD_REQUEST,
      });

      const response: any =
        type === 'charge'
          ? await getOutsidesDowloadDebtsService(invoiceId, cnp)
          : await getOutsidesDowloadInvoiceService(invoiceId, cnp, enrollmentId);

      const anchorElement = document.createElement('a');

      document.body.appendChild(anchorElement);

      anchorElement.style.display = 'none';

      const blob = new Blob([response], {
        type: 'application/pdf',
      });

      const url = window.URL.createObjectURL(blob);

      anchorElement.href = url;
      anchorElement.download = `Boleto-${invoiceId}`;
      anchorElement.click();

      window.URL.revokeObjectURL(url);

      return dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_DOWNLOAD_METHOD_SUCCESS,
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_DOWNLOAD_METHOD_FAILURE,
        payload: {
          status: error?.response?.data.status,
          state: true,
        },
      });
    }
  };

const getEasyInvoicesCredentialsByToken =
  (token: string) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_EASY_INVOICES_CREDENTIALS_BY_TOKEN_REQUEST,
      });

      const response = (await getCredentialsByTokenService(token)) as {
        cnp: string;
        enrollment_id: string;
      };

      return dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_EASY_INVOICES_CREDENTIALS_BY_TOKEN_SUCCESS,
        payload: {
          cnp: response.cnp,
          enrollment: response.enrollment_id,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_EASY_INVOICES_CREDENTIALS_BY_TOKEN_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const getOutsidesSocialTariffEnrollmentsRequest =
  (cnp: string) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_GET_SOCIAL_TARIFF_ENROLLMENTS_REQUEST,
      });

      const response = (await getOutsidesSocialTariffEnrollmentsService(
        cnp,
      )) as IResponseSocialTariffEnrollments;

      return dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_GET_SOCIAL_TARIFF_ENROLLMENTS_SUCCESS,
        payload: {
          ...response,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_GET_SOCIAL_TARIFF_ENROLLMENTS_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const getErrorCloseOutsidesSocialTariffEnrollments =
  () => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesOutsides.OUTSIDES_GET_SOCIAL_TARIFF_ENROLLMENTS_FAILURE_CLOSE,
    });
  };

const getOutsidesSocialTariffValidEnrollmentRequest =
  (data: IRequestSocialTariffValidEnrollment, dataEnrollment: any) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_GET_SOCIAL_TARIFF_VALID_ENROLLMENT_REQUEST,
      });

      const response = (await getOutsidesSocialTariffValidEnrollmentService(
        data,
      )) as IResponseSocialTariffValidEnrollment;

      const operation = response?.operation.toLocaleUpperCase();
      const warning = operation
        ? operation !== 'RIO DE JANEIRO' && operation !== 'CUIABA'
        : false;

      if (response?.result === 'Matrícula vinculada ao consumidor') {
        if (warning) {
          return dispatch({
            type: RepositoriesTypesOutsides.OUTSIDES_GET_SOCIAL_TARIFF_VALID_ENROLLMENT_FAILURE,
            payload: {
              warning,
            },
          });
        }

        Navigation.navigate(RouteName.SOCIALTARIFFOUTSIDESOLICITATION, {
          ...dataEnrollment,
          renovation: response?.renovation,
        });

        return dispatch({
          type: RepositoriesTypesOutsides.OUTSIDES_GET_SOCIAL_TARIFF_VALID_ENROLLMENT_SUCCESS,
          payload: {
            typeSolicitation: 'default',
            renovation: response?.renovation,
            dataEnrollment,
          },
        });
      }

      if (
        response?.result === 'Troca de titularidade' ||
        response?.result === 'Recadastramento'
      ) {
        return dispatch({
          type: RepositoriesTypesOutsides.OUTSIDES_GET_SOCIAL_TARIFF_VALID_ENROLLMENT_SUCCESS,
          payload: {
            typeSolicitation:
              response?.result === 'Troca de titularidade'
                ? 'changeOwnership'
                : 're-registration',
            renovation: response?.renovation,
            dataEnrollment,
          },
        });
      }

      return dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_GET_SOCIAL_TARIFF_VALID_ENROLLMENT_FAILURE,
        payload: {
          error: {
            status: 400,
            state: true,
          },
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_GET_SOCIAL_TARIFF_VALID_ENROLLMENT_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const getErrorCloseOutsidesSocialTariffValidEnrollment =
  () => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesOutsides.OUTSIDES_GET_SOCIAL_TARIFF_VALID_ENROLLMENT_FAILURE_CLOSE,
    });
  };

const postOutsidesSocialTariffRequest =
  (data: IRequestSocialTariff) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_POST_SOCIAL_TARIFF_REQUEST,
      });

      const response = (await postOutsidesSocialTariffService(data)) as {
        protocol: string;
      };

      return dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_POST_SOCIAL_TARIFF_SUCCESS,
        payload: {
          ...response,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesOutsides.OUTSIDES_POST_SOCIAL_TARIFF_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const postErrorCloseOutsidesSocialTariff = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesOutsides.OUTSIDES_POST_SOCIAL_TARIFF_FAILURE_CLOSE,
  });
};

const activateRouteReportLackWater = () => (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesOutsides.OUTSIDES_ROUTE_REPORT_LACK_WATER_ACTIVATE,
  });
};

const clearRouteReportLackWater = () => (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesOutsides.OUTSIDES_ROUTE_REPORT_LACK_WATER_CLEAR,
  });
};

export {
  getEasyDuplicateInvoices,
  getErrorCloseEasyDuplicateInvoices,
  clearEasyDuplicateInvoices,
  activateModalDuplicateInvoices,
  clearModalDuplicateInvoices,
  getOutsidesInvoicesInstallmentsRequest,
  getErrorCloseOutsidesInvoicesInstallments,
  postOutsidesInvoicePaymentRequest,
  postErrorCloseOutsidesInvoicePayment,
  activateModalSalesforceSatisfaction,
  clearModalSalesforceSatisfaction,
  getDischargeDebtsRequest,
  getErrorCloseDischargeDebts,
  clearDischargeDebts,
  getDischargeDebtsInstallmentsRequest,
  getErrorCloseDischargeDebtsInstallments,
  postDischargeDebtsPaymentRequest,
  postErrorCloseDischargeDebtsPayment,
  getOutsidesRegistrationUpdateRequest,
  postOutsidesRegistrationUpdateRequest,
  postErrorCloseOutsidesRegistrationUpdate,
  activateModalDataUpdate,
  clearModalDataUpdate,
  getInvoiceDownload,
  getEasyDuplicateInvoicesByToken,
  getEasyInvoicesCredentialsByToken,
  getOutsidesSocialTariffEnrollmentsRequest,
  getErrorCloseOutsidesSocialTariffEnrollments,
  getOutsidesSocialTariffValidEnrollmentRequest,
  getErrorCloseOutsidesSocialTariffValidEnrollment,
  postOutsidesSocialTariffRequest,
  postErrorCloseOutsidesSocialTariff,
  activateRouteReportLackWater,
  clearRouteReportLackWater,
};
