import styled from 'styled-components';

import { Breakpoints } from '~/styles/breakpoints';
import { Color } from '~/styles/colors';

export const Main = styled.main`
  padding: 80px 20px;
  max-width: 560px;
  margin-inline: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 56px 20px 0 24px; */
  /* margin: 0; */
  position: relative;
  div {
    :first-of-type {
      min-height: inherit;
    }
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    /* padding: 128px 0 120px 0; */
  }
`;

export const DescriptionTextStyled = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  color: ${Color.blueIntense};

  b {
    font-weight: 700;
  }
`;

export const NavWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  button {
    max-width: inherit;
  }
`;

export const ContainerMessageBox = styled.div`
  width: 100%;
`;
