import React, { FC, useEffect } from 'react';
import ReactGA from 'react-ga4';

import { Button, Container, Content, Text } from './styles';
import { CloseIcon } from '../icons';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { clearWaterSupplyInterrupted } from '~/store/modules/enrollmentsAuth/actions';

const SupplyOccurrenceWarning: FC = () => {
  const dispatch = useDispatch();

  const registrationCheck = useSelector(
    (item: IState) => item.enrollmentsAuth.registrationCheck,
  );

  const handleClearWaterSupplyInterrupted = (
    close_water_supply_interrupted: boolean,
  ) => {
    dispatch(clearWaterSupplyInterrupted(close_water_supply_interrupted));
  };

  useEffect(() => {
    if (!registrationCheck?.water_supply_interrupted_registered_event) {
      ReactGA.event({
        category: 'Banner de ocorrência de abastecimento',
        action: '[WEB][AL][Apresentação] - Banner de ocorrência de abastecimento',
      });

      handleClearWaterSupplyInterrupted(false);
    }
  }, []);

  return (
    <Container active className="menu-dropdown">
      <Content className="menu-dropdown">
        <Text>
          Possível interrupção de água hoje por questões operacionais. Lamentamos o
          transtorno.
        </Text>
        <Button
          type="button"
          onClick={() => handleClearWaterSupplyInterrupted(true)}
        >
          <CloseIcon />
        </Button>
      </Content>
    </Container>
  );
};

export default SupplyOccurrenceWarning;
