import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import ArrowSVG from '~/assets/Icons/Arrow';
import {
  Container,
  ContainerBorder,
  ContainerMobile,
  ContentMobile,
  DetailButton,
  ProtocolText,
  RowMobile,
  RowMobileButton,
  Text,
} from './styles';
import { Breakpoints } from '~/styles';
import { ContainerColumn } from '../styles';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { IOrderService } from '~/store/modules/orderServices/types';

interface IProps {
  data: IOrderService;
}

const ContainerNegotiation: FC<IProps> = ({ data }) => {
  const isLaptop = useMediaQuery({ minWidth: Breakpoints.laptop.min });

  const status = data?.status?.label;
  const color = data?.status?.color;

  const isCanceled = status?.toUpperCase() === 'CANCELADO';

  const handleDetailSolicitation = () => {
    Navigation.navigate(
      data?.info
        ? RouteName.SUPPORTCENTERPROTOCOL
        : RouteName.SUPPORTCENTERSERVICEORDER,
      data,
    );
  };

  return isLaptop ? (
    <Container
      color={color}
      isCanceled={isCanceled}
      onClick={handleDetailSolicitation}
    >
      <ContainerBorder color={color}>
        <span />
      </ContainerBorder>
      <ContainerColumn flexBasis={25}>
        <Text>{data?.protocol}</Text>
      </ContainerColumn>
      <ContainerColumn flexBasis={25}>
        <Text color={color} textAlign="left" isBold>
          {data?.step && <span>Etapa {data?.step}</span>}
          {status}
        </Text>
      </ContainerColumn>
      <ContainerColumn flexBasis={40}>
        <Text textAlign="left">{data?.serviceName}</Text>
      </ContainerColumn>
      <ContainerColumn flexBasis={10}>
        <DetailButton type="button" onClick={handleDetailSolicitation}>
          <ArrowSVG />
        </DetailButton>
      </ContainerColumn>
    </Container>
  ) : (
    <ContainerMobile
      color={color}
      isCanceled={isCanceled}
      onClick={handleDetailSolicitation}
    >
      <ContainerBorder color={color}>
        <span />
      </ContainerBorder>
      <ContentMobile>
        <RowMobile>
          <Text>Protocolo:</Text> &ensp;<ProtocolText>{data?.protocol}</ProtocolText>
        </RowMobile>
        <RowMobile>
          <Text>{data?.serviceName}</Text>
        </RowMobile>
        <RowMobile>
          <Text color={color} isBold>
            {status} {data?.step && <span>Etapa {data?.step}</span>}
          </Text>
        </RowMobile>
        <RowMobileButton>
          <DetailButton type="button" onClick={handleDetailSolicitation}>
            <ArrowSVG />
          </DetailButton>
        </RowMobileButton>
      </ContentMobile>
    </ContainerMobile>
  );
};

export default ContainerNegotiation;
