import styled, { css } from 'styled-components';
import { Breakpoints } from '~/styles';

interface IPropsContainer {
  active: boolean;
}

export const Container = styled.div<IPropsContainer>`
  width: 100%;
  min-height: 88px;
  padding-inline: 20px;
  box-sizing: border-box;
  display: flex;
  opacity: 1;
  background: #872d2d;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 0;
  top: 128px;
  z-index: 700;
  transition: height 0.3s, opacity 0.3s;

  ${({ active }) =>
    !active &&
    css`
      z-index: -1;
      height: 0;
      opacity: 0;
      display: none;
    `}

  @media (min-width: ${Breakpoints.tablet}) {
    padding-inline: 80px;
  }
`;

export const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1170px;
  padding: 18.5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: nowrap;

  @media (min-width: ${Breakpoints.tablet}) {
    padding: 28px 0;
  }
`;

export const Text = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
`;

export const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
  }
`;
