import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

interface IPropsStatusInvoice {
  color?: string;
  isCanceled?: boolean;
}

export const ContainerBorder = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  span {
    position: absolute;
    left: 0;
    height: 100%;
    width: 8px;
    background: ${({ color }) => color};
    border-radius: 8px 0 0 8px;
  }
`;

export const DetailButton = styled.button<IPropsStatusInvoice>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: #e9ecef;
  border-radius: 8px;
  cursor: pointer;
  visibility: ${({ isCanceled }) => isCanceled && 'hidden'};

  svg {
    rotate: 180deg;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    margin-left: auto;
    margin-right: 16px;
  }
`;

export const Container = styled.div<{ isCanceled: boolean }>`
  width: 100%;
  height: 62px;
  border: 1px solid ${({ color }) => color};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 12px;
  background: ${({ isCanceled }) => (isCanceled ? '#F2F2F2' : 'transparent')};
  cursor: pointer;
`;

export const ContainerMobile = styled.div<IPropsStatusInvoice>`
  width: 100%;
  height: 104px;
  border: 1px solid ${({ color }) => color};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  background: ${({ isCanceled }) => (isCanceled ? '#F2F2F2' : 'transparent')};
  cursor: pointer;
`;

export const ContentMobile = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;

  .status {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: ${({ color }) => color};

    span {
      color: #000;
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
    }
  }
`;

export const RowMobile = styled.p`
  display: flex;
  align-items: flex-start;
  padding: 0 16px;
`;

export const RowMobileButton = styled.div`
  position: absolute;
  right: 16px;
`;

export const Text = styled.p<{
  color?: string;
  textAlign?: string;
  isBold?: boolean;
}>`
  font-weight: ${({ isBold }) => (isBold ? 600 : 300)};
  font-size: 14px;
  line-height: 17px;
  text-align: ${({ textAlign }) => textAlign || 'center'};
  color: ${({ color }) => color || Color.black};

  span {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #999999;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    flex: 1;
  }
`;

export const ProtocolText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #37b4c8;
`;
