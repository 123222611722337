import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';

import { getEnrollmentsInvoicesDetailRequest } from '~/store/modules/enrollmentsInvoices/actions';
import { getEnrollmentsPaymentReceiptRequest } from '~/store/modules/enrollmentsPayment/actions';
import { InvoiceReceiptIcon } from '../icons';
import { IInvoices } from '~/store/modules/enrollmentsInvoices/types';
import { statusColor } from '~/utils/invoiceStatus';
import formatter from '~/utils/formatter';
import ArrowSVG from '~/assets/Icons/Arrow';
import { IState } from '~/store/modules/types';
import { Container, ContainerMobile } from './styles';
import { Breakpoints } from '~/styles';

interface IProps {
  invoice: IInvoices;
}

const ContainerInvoice: FC<IProps> = ({ invoice }) => {
  const isLaptop = useMediaQuery({ minWidth: Breakpoints.laptop.min });

  const dispatch = useDispatch();

  const enrollment = useSelector(
    (item: IState) => item.enrollmentsAuth.registrationCheck?.enrollment,
  );

  const handleGetEnrollmentsInvoicesDetailRequest = () =>
    dispatch(getEnrollmentsInvoicesDetailRequest(invoice?.invoice_id));

  return isLaptop ? (
    <Container
      color={statusColor(invoice?.status?.label, true)}
      onClick={handleGetEnrollmentsInvoicesDetailRequest}
    >
      <div className="container-border">
        <span />
      </div>
      <p className="status" style={{ textTransform: 'capitalize' }}>
        {invoice?.status?.label.toLocaleLowerCase()} <br />{' '}
        <span>{invoice?.reference}</span>
      </p>
      <p className="small">{formatter.formatCurrency(invoice?.amount)}</p>
      <p>
        {invoice?.status.label === 'PROCESSANDO' ||
        invoice?.status.label === 'QUITADA' ? (
          <div className="container-receipt">
            {invoice?.type_payment === 'CARD_DIGIIGUA' && (
              <>
                <button
                  type="button"
                  onClick={(ev) => {
                    ev.stopPropagation();
                    dispatch(
                      getEnrollmentsPaymentReceiptRequest({
                        enrollmentId: enrollment || '',
                        invoiceId: invoice?.invoice_id || 0,
                      }),
                    );
                  }}
                  className="button-receipt"
                >
                  <InvoiceReceiptIcon />
                </button>{' '}
                <span>Comprovante</span>
              </>
            )}
            <button
              type="button"
              onClick={handleGetEnrollmentsInvoicesDetailRequest}
              className="button-detail"
            >
              <ArrowSVG />
            </button>
          </div>
        ) : (
          <div className="container-receipt">
            <span />
            {moment(invoice?.maturity_date).format('DD/MM/YYYY')}
            <button
              type="button"
              onClick={handleGetEnrollmentsInvoicesDetailRequest}
              className="button-payment"
            >
              <span>Pagar</span>
              <ArrowSVG />
            </button>
          </div>
        )}
      </p>
    </Container>
  ) : (
    <ContainerMobile
      color={statusColor(invoice?.status?.label, true)}
      onClick={handleGetEnrollmentsInvoicesDetailRequest}
    >
      <div className="container-border">
        <span />
      </div>
      <div className="content">
        <p>
          <span>{invoice?.reference}</span>
          <span className="status" style={{ textTransform: 'capitalize' }}>
            {invoice?.status?.label.toLocaleLowerCase()}{' '}
          </span>
        </p>
        <p>{formatter.formatCurrency(invoice?.amount)}</p>
        <p>
          <span
            style={{
              visibility:
                invoice?.status.label === 'PROCESSANDO' ||
                invoice?.status.label === 'QUITADA'
                  ? 'hidden'
                  : 'visible',
            }}
          >
            {invoice?.status.label === 'PROCESSANDO' ? 'Vence ' : 'Vencida '} em{' '}
            {moment(invoice?.maturity_date).format('DD/MM/YYYY')}
          </span>
          <button
            type="button"
            onClick={handleGetEnrollmentsInvoicesDetailRequest}
            className="button-payment"
          >
            <span>Pagar</span>
            <ArrowSVG />
          </button>
        </p>
      </div>
    </ContainerMobile>
  );
};

export default ContainerInvoice;
