import jwtDecode from 'jwt-decode';
import { Dispatch } from 'redux';
import ReactGA from 'react-ga4';
import { v4 } from 'uuid';
import { RouteName } from '~/routes/Routes.name';
import {
  getEnrollmentsSessionsService,
  getEnrollmentsMeService,
} from '~/services/enrollmentsAuth';
import { getEnrollmentsUsersInfoService } from '~/services/enrollmentsUsers';
import { togglesEnrollmentServices, togglesMeServices } from '~/services/toggles';
import formatter from '~/utils/formatter';
import Navigation from '~/utils/Navigation';
import {
  IResponseUsersInfo,
  RepositoriesTypesUsers,
} from '../enrollmentsUsers/types';
import {
  IResponse,
  RepositoriesTypesEnrollmentsTogglesEnrollment,
} from '../togglesEnrollment/types';
import { RepositoriesTypesEnrollmentsTogglesMe } from '../togglesMe/types';
import {
  RepositoriesTypesAuth,
  IRequestEnrollmentsSessions,
  IResponseEnrollmentsSessions,
  IResponseEnrollmentsMe,
  IRegistrationCheck,
} from './types';
import { apiHeaderReset, apiHeaderSetSession } from '~/utils/apiSession';
import { IState } from '../types';
import {
  apiHeaderResetRecaptchaToken,
  apiHeaderSetRecaptchaToken,
} from '~/utils/apiRecaptchaToken';

const getEnrollmentsAuthRequest =
  ({
    cnp,
    password,
    recaptchaTokenV3,
    recaptchaTokenV2,
  }: IRequestEnrollmentsSessions) =>
  async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesAuth.ENROLLMENTS_AUTH_METHOD_REQUEST,
      });

      dispatch({
        type: RepositoriesTypesAuth.ENROLLMENTS_SESSIONS_FIRST_ACCESS_CLEAR,
      });

      apiHeaderSetRecaptchaToken({
        recaptchaTokenV2,
        recaptchaTokenV3,
      });

      apiHeaderSetSession();

      const responseSessions = (await getEnrollmentsSessionsService({
        cnp: formatter.formatarNumber(cnp || '') || '',
        password,
        recaptchaTokenV3,
        recaptchaTokenV2,
      })) as IResponseEnrollmentsSessions;

      localStorage.setItem('@digiIgua_data_userLogin', responseSessions?.token);

      const {
        isClient,
        isManager,
        isEntrepreneur,
      }: { isClient: boolean; isManager: boolean; isEntrepreneur: boolean } =
        jwtDecode(responseSessions?.token);

      dispatch({
        type: RepositoriesTypesAuth.ENROLLMENTS_GET_SESSIONS_METHOD_SUCCESS,
        payload: {
          data: responseSessions?.token,
          IDSN: v4(),
        },
      });

      dispatch({
        type: RepositoriesTypesAuth.ENROLLMENTS_AUTH_METHOD_SUCCESS,
        payload: {
          data: password,
          isClient,
          isManager,
          isEntrepreneur,
        },
      });

      const responseMe =
        (await getEnrollmentsMeService()) as IResponseEnrollmentsMe[];

      const { outsides } = getState() as IState;

      if (outsides?.reportLackWaterRoute) {
        if (responseMe.length > 0) {
          ReactGA.event({
            category: 'Falta d’água',
            action: "[WEB][Login][falta d'água][Cliente com matriculas]",
          });
        } else {
          ReactGA.event({
            category: 'Falta d’água',
            action: "[WEB][Login][falta d'água][Cliente sem matriculas]",
          });
        }
      }

      if (responseMe && responseMe?.length > 0 && responseMe?.length < 2) {
        dispatch({
          type: RepositoriesTypesAuth.ENROLLMENTS_REGISTRATION_CHECK_REQUEST,
          payload: {
            data: responseMe[0],
          },
        });

        const responseTogglesEnrollment = (await togglesEnrollmentServices(
          responseMe[0].enrollment,
        )) as IResponse[];

        dispatch({
          type: RepositoriesTypesEnrollmentsTogglesEnrollment.TOGGLES_ENROLLMENT_GET_METHOD_SUCCESS,
          payload: {
            data: responseTogglesEnrollment,
          },
        });
      }

      dispatch({
        type: RepositoriesTypesAuth.ENROLLMENTS_GET_ME_METHOD_SUCCESS,
        payload: {
          data: responseMe,
        },
      });

      const responseUsersInfo =
        (await getEnrollmentsUsersInfoService()) as IResponseUsersInfo;

      dispatch({
        type: RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_METHOD_SUCCESS,
        payload: {
          data: responseUsersInfo,
        },
      });

      const responseTogglesMe = (await togglesMeServices()) as IResponse[];

      apiHeaderResetRecaptchaToken();

      return dispatch({
        type: RepositoriesTypesEnrollmentsTogglesMe.TOGGLES_ME_GET_METHOD_SUCCESS,
        payload: {
          data: responseTogglesMe,
        },
      });
    } catch (error: any) {
      apiHeaderReset();
      apiHeaderResetRecaptchaToken();

      return dispatch({
        type: RepositoriesTypesAuth.ENROLLMENTS_AUTH_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
            message: error?.response?.data?.type,
          },
        },
      });
    }
  };

const getEnrollmentsAuthCloseRequest = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesAuth.ENROLLMENTS_AUTH_METHOD_FAILURE_CLOSE,
  });
};

const clearFirstAccess = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesAuth.ENROLLMENTS_SESSIONS_FIRST_ACCESS_CLEAR,
  });
};

const getEnrollmentsAuthDestroySessionRequest = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesAuth.ENROLLMENTS_DESTROY_SESSION_REQUEST,
  });
  dispatch({
    type: RepositoriesTypesUsers.ENROLLMENTS_GET_USERS_METHOD_CLEAR,
  });
};

const setEnrollmentsAuthRegistratioCheckRequest =
  (registrationCheck: IRegistrationCheck) =>
  async (dispatch: Dispatch, getState: any) => {
    dispatch({
      type: RepositoriesTypesAuth.ENROLLMENTS_REGISTRATION_CHECK_REQUEST,
      payload: {
        data: registrationCheck,
      },
    });

    const { enrollmentsAuth } = getState() as IState;

    Navigation.navigate(enrollmentsAuth.routeAuthRedirect || RouteName.HOME);

    const response = (await togglesEnrollmentServices(
      registrationCheck.enrollment,
    )) as IResponse[];

    return dispatch({
      type: RepositoriesTypesEnrollmentsTogglesEnrollment.TOGGLES_ENROLLMENT_GET_METHOD_SUCCESS,
      payload: {
        data: response,
      },
    });
  };

const activateModalAuth =
  (routeAuthRedirect?: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: RepositoriesTypesAuth.ENROLLMENTS_AUTH_ROUTE_REDIRECT_CLEAR,
    });

    return dispatch({
      type: RepositoriesTypesAuth.ENROLLMENTS_AUTH_ACTIVATE_MODAL,
      payload: {
        data: routeAuthRedirect,
      },
    });
  };

const clearModalAuth = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesAuth.ENROLLMENTS_AUTH_CLEAR_MODAL,
  });
};

const setRouteAuthRedirect =
  (routeAuthRedirect: string) => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesAuth.ENROLLMENTS_SET_AUTH_ROUTE_REDIRECT,
      payload: {
        data: routeAuthRedirect,
      },
    });
  };

const clearRouteAuthRedirect = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesAuth.ENROLLMENTS_AUTH_ROUTE_REDIRECT_CLEAR,
  });
};

const activateModalEnrollmentNotExists = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesAuth.ENROLLMENTS_NOT_EXISTS_ACTIVATE_MODAL,
  });
};

const clearModalEnrollmentNotExists = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesAuth.ENROLLMENTS_NOT_EXISTS_CLEAR_MODAL,
  });
};

const updateCurrentPassword = (password: string) => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesAuth.ENROLLMENTS_UPDATE_CURRENT_PASSWORD,
    payload: {
      data: password,
    },
  });
};

const clearWaterSupplyInterrupted =
  (close_water_supply_interrupted: boolean) =>
  async (dispatch: Dispatch, getState: any) => {
    const { enrollmentsAuth } = getState() as IState;

    const { registrationCheck, registrations } = enrollmentsAuth;

    const updatedList = registrations?.map((item) => {
      if (item.enrollment === registrationCheck?.enrollment) {
        return {
          ...item,
          water_supply_interrupted: !close_water_supply_interrupted,
          water_supply_interrupted_registered_event: true,
        };
      }
      return item;
    });

    return dispatch({
      type: RepositoriesTypesAuth.WATER_SUPPLY_INTERRUPTED_CLEAR,
      payload: {
        data: {
          registrations: updatedList,
          registrationCheck: {
            ...registrationCheck,
            water_supply_interrupted: !close_water_supply_interrupted,
            water_supply_interrupted_registered_event: true,
          },
        },
      },
    });
  };

export {
  getEnrollmentsAuthRequest,
  getEnrollmentsAuthCloseRequest,
  getEnrollmentsAuthDestroySessionRequest,
  setEnrollmentsAuthRegistratioCheckRequest,
  clearFirstAccess,
  activateModalAuth,
  clearModalAuth,
  setRouteAuthRedirect,
  clearRouteAuthRedirect,
  activateModalEnrollmentNotExists,
  clearModalEnrollmentNotExists,
  updateCurrentPassword,
  clearWaterSupplyInterrupted,
};
