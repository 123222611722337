import styled from 'styled-components';
import { Color } from '~/styles';

import { Breakpoints } from '~/styles/breakpoints';

export const Main = styled.main`
  padding: 80px 20px;
  max-width: 570px;
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 56px 10px;

  p {
    color: ${Color.blueIntense};
    font-size: 16px;
    font-weight: 500;
    margin-top: 24px;
    line-height: 22px;
    text-align: center;
  }

  div {
    :first-of-type {
      min-height: inherit;

      h1 {
        margin: 0;
      }
    }
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 102px 0;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  margin-block: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    margin-block: 40px;
    gap: 40px;
  }
`;

export const MessageError = styled.p`
  margin-bottom: 24px;
  max-width: 400px;
  color: rgb(153, 153, 153);
  text-align: center;
  font-weight: 500;
  line-height: 1.2;
`;
