import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import LoadingComponent from '~/components/Loading';
import { IState } from '~/store/modules/types';

import { Margin } from '~/components/Margin';
import {
  Main,
  ContainerBreadCrumb,
  Card,
  ContainerBorder,
  Border,
  Container,
  ContainerHeader,
  ContainerColumn,
  HeaderText,
} from './styles';
import { BreadCrumb } from '~/components/BreadCrumb';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import ContainerNegotiation from './ContainerNegotiation';
import Pagination from './ContainerNegotiation/Pagination';
import EmptyMessage from '~/components/EmptyMessage';
import { BannerApp } from '~/components/BannerApp';
import {
  getErrorCloseServiceOrdersListRequest,
  getOrderServicesListRequest,
  postErrorCloseServiceOrderAttachmentsRequest,
} from '~/store/modules/orderServices/actions';

const SupportCenterNew: React.FC = () => {
  const dispatch = useDispatch();

  const data = useSelector((item: IState) => item.orderServices.data);
  const loading = useSelector((item: IState) => item.orderServices.loading);

  useEffect(() => {
    dispatch(getErrorCloseServiceOrdersListRequest());
    dispatch(postErrorCloseServiceOrderAttachmentsRequest());
    dispatch(getOrderServicesListRequest({ page: 1, size: 6 }));
  }, []);

  console.log({ data });

  return (
    <Main>
      {loading && <LoadingComponent labelWait="Carregando..." />}
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () => Navigation.navigate(RouteName.HOME),
            },
            {
              label: 'Minhas solicitações',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      <Container>
        <BannerApp />
        <Card>
          <h1>Minhas solicitações</h1>
          <ContainerBorder>
            <Border />
          </ContainerBorder>
          <ContainerHeader>
            <ContainerColumn flexBasis={25}>
              <HeaderText>Protocolo</HeaderText>
            </ContainerColumn>
            <ContainerColumn flexBasis={25}>
              <HeaderText textAlign="left">Status</HeaderText>
            </ContainerColumn>
            <ContainerColumn flexBasis={50}>
              <HeaderText textAlign="left">Tipo</HeaderText>
            </ContainerColumn>
          </ContainerHeader>
          {data && data?.data && data?.data?.length > 0 ? (
            data?.data?.map((orderService) => (
              <ContainerNegotiation key={orderService.caseId} data={orderService} />
            ))
          ) : (
            <>
              <Margin height={70} />
              <EmptyMessage>
                Você não possui <br /> solicitações anteriores!
              </EmptyMessage>
              <Margin height={70} />
            </>
          )}
          {data && data?.pagination && data?.pagination?.maxPages > 1 && (
            <Pagination />
          )}{' '}
        </Card>
      </Container>
    </Main>
  );
};

export default SupportCenterNew;
