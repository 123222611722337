import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Breakpoints } from '~/styles/breakpoints';
import { Color } from '~/styles/colors';

export const Main = styled.main`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 28px 20px 0 24px;
  margin: 0;
  position: relative;
  max-width: 570px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    min-height: 100vh;
    height: auto;
    padding-bottom: 28px;
  }
  @media screen and (max-width: ${Breakpoints.mobile.max}px) {
    flex-direction: column;
  }
`;

export const CardForm = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.09);
  border-radius: 24px;
  padding: 0 18px;
  width: calc(100% - 36px);
  min-height: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    max-width: calc(609px - 160px);
    min-height: 680px;
    padding: 0 100px;
  }
`;

export const TitleTextStyled = styled.h1`
  font-weight: 700;
  font-size: 18px;
  color: ${Color.blueIntense};
  margin-bottom: 16px;
`;

export const DescriptionTextStyled = styled.p`
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: ${Color.blueIntense};
  margin-bottom: 10px;
  width: 80%;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    font-size: 16px;
  }
`;

export const TitleInputRadio = styled.p`
  color: ${Color.blueIntense};
  font-weight: 500;
  font-size: 16px;
  align-self: flex-start;
`;

export const ContainerInputRadio = styled.div`
  margin-right: 50px;
  width: calc(100% - 50px);
  display: flex;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;
  }
`;

export const InputRadio = styled.input.attrs({
  type: 'radio',
})`
  all: unset;
  background-color: ${Color.grayLightSeconde};
  height: 15px;
  width: 15px;
  border-radius: 50%;
  box-shadow: inset 0px 2.76838px 17.9945px rgba(0, 0, 0, 0.19);
  cursor: pointer;
  margin-right: 10px;
  display: flex;

  &:checked {
    border: 3px solid ${Color.grayLightSeconde};
    background: ${Color.blueIntense};
    height: 12px;
    width: 12px;
  }
`;

export const LabelRadio = styled.label`
  font-weight: 500;
  font-size: 14px;
  color: ${Color.grayLightSeconde};
`;

export const SubTitle = styled.div`
  position: relative;
  color: ${Color.gray};
  width: 100%;
  display: flex;
  justify-content: center;
  p {
    background: #ffffff;
    z-index: 2;
    padding: 0px 10px;
    font-size: 19px;
    font-weight: 500;
  }
  hr {
    border-bottom: 1px solid ${Color.gray};
    border-top: transparent;
    position: absolute;
    width: 100%;
  }
`;

export const ButtonGoBack = styled(Link)`
  color: ${Color.gray};
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  line-height: 21px;
`;

export const ContainerTermsStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
`;

export const TextTerm = styled.p`
  font-size: 0.875rem;
  line-height: 1.3;
  font-weight: 400;
  color: ${Color.blueIntense};
  width: fit-content;

  a {
    text-decoration: none;
    font-weight: 700;
    color: ${Color.red};
  }
`;
